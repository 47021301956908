const CART_ID_NAME = 'cartIdV2';
const AUTH_TOKEN_NAME = 'auth_token';

class Http {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  getUrl(path) {
    const url = this.baseUrl + path;
    if (window.api_host) {
      return window.api_host + url;
    }
    return url;
  }

  getCartId() {
    return window.localStorage.getItem(CART_ID_NAME);
  }

  getAuthToken() {
    return window.localStorage.getItem(AUTH_TOKEN_NAME);
  }

  clearCart() {
    window.localStorage.removeItem(CART_ID_NAME);
  }

  get(url, auth) {
    return window.$.ajax({
      url: this.getUrl(url),
      method: 'GET',
      beforeSend: xhr => {
        xhr.setRequestHeader('X-Cart-Id', this.getCartId());
        if (auth) {
          xhr.setRequestHeader(
            'Authorization', 'Wellbe ' + this.getAuthToken()
          );
        }
      }
    });
  }

  post(url, data) {
    return window.$.ajax({
      url: this.getUrl(url),
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      beforeSend: xhr => {
        xhr.setRequestHeader('X-Cart-Id', this.getCartId());
      },
      success: (data, textStatus, request) => {
        // set whatever you get from api, if there is a difference
        // we should use api as source or truth
        window.localStorage.setItem(
            CART_ID_NAME, request.getResponseHeader('X-Cart-Id')
        );
      }
    });
  }

  put(url, data) {
    return window.$.ajax({
      url: this.getUrl(url),
      method: 'PUT',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(data),
      beforeSend: xhr => {
        xhr.setRequestHeader('X-Cart-Id', this.getCartId());
      }
    });
  }

  delete(url) {
    return window.$.ajax({
      url: this.getUrl(url),
      method: 'DELETE',
      contentType: 'application/json; charset=utf-8',
      beforeSend: xhr => {
        xhr.setRequestHeader('X-Cart-Id', this.getCartId());
      }
    });
  }
}

class ShopRestClient {
  constructor(http) {
    this.http = http;
  }

  clearCart() {
    this.http.clearCart();
  }

  getCartItems() {
    return this.http.get('/shop/items/');
  }

  addToCart(productSlug) {
    return this.http.post('/shop/items/', {product_slug: productSlug});
  }

  updateCartItemQuantity(productSlug, quantity) {
    return this.http.put(`/shop/items/${productSlug}/quantity/`, {quantity});
  }

  removeFromCart(productSlug) {
    return this.http.delete(`/shop/items/${productSlug}/`);
  }

  shopCheckout(data) {
    return this.http.post('/shop/checkout/', data);
  }
}

export { Http, ShopRestClient };
export default ShopRestClient;
