import { Http, ShopRestClient } from 'lib/http';
import App, { renderUI } from 'App';

console.log('LOADED');

window.$(document).ready(() => {
  console.log('READY');
  setTimeout(() => {
    const restClient = new ShopRestClient(new Http('/api/v2'));
    const webShopUI = new App(restClient, event => {
      console.log('event', event);
      if (event.fb) {
        try {
          const [name, options] = event.fbObject;
          window.fbq('track', name, options);
        } catch (e) {
          console.log('fbq', e);
        }
      }
      renderUI(event.items, (a, p) => webShopUI.onAction(a, p));
    });
    // attach this globally so we can override it
    window.webShopAPI = restClient;
    window.webShopUI = webShopUI;
  }, 100);
});
