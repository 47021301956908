import React from 'react';
import styled from 'styled-components';

const CartWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 250px;
  padding: 10px 15px;
  border-radius: 0 0 4px 4px;
  background: #1a1a1a;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 1px;
  overflow: hidden;
  transition: 0.1s;
`;

const Container = styled.div`
  & > a [class^="fa fa-"] {
    transition: 0.2s;
    margin-right: 7px;
    opacity: 0.5;
    filter: alpha(opacity=50);
    font-size: 15px;
    line-height: 0;
    position: relative;
  }
  &:hover > a [class^="fa fa-"] {
    opacity: 1;
    filter: none;
  }
  &:hover ${CartWrapper} {
    opacity: 1;
    filter: none;
    height: auto;
    overflow: visible;
  }
`;

const CartItems = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const CartItem = styled.li`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #f3f3f3;
  & > a {
    display: block;
    color: #fff;
    overflow: hidden;
  }
`;

const Title = styled.h5`
  font-size: 13px;
  color: #d9d9d9;
  margin-bottom: 3px;
  transition: 0.3s;
  &:hover {
    color: #fff;
  }
`;

const Thumb = styled.img`
  width: 50px;
  float: left;
  margin-right: 12px;
  border-radius: 3px;
`;

const ItemsCount = styled.strong`
  color: #f00a71;
  padding: 1px 5px;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Button = styled.a`
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  padding: 5px 10px;
  border-radius: 3px;
  text-shadow: none;
  box-shadow: none;
  background: #2a8fbd;
  border: 1px solid #2681aa;
  &:active, &:hover {
    outline: 0;
  }
  &:hover {
    color: #ffffff;
    background: #227297;
    border-color: #227297;
  }
`;

const PriceContainer = styled.span`
  font-size: 12px;
  margin-bottom: 0;
  transition: 0.3s;
  color: #fff;
`;

const PriceAmount = styled.strong`
  font-size: 12px;
  margin-bottom: 0;
  transition: 0.3s;
  ${props => props.discount && `
    color: #616161;
    text-decoration: line-through;
    margin-left: 5px;
  `}
`;

const Price = ({ price, discountPrice }) => {
  return (
    <PriceContainer>
      <PriceAmount>{price} RSD</PriceAmount>
      {discountPrice ? (
        <PriceAmount discount={true}>
          {discountPrice} RSD
        </PriceAmount>
      ) : null}
    </PriceContainer>
  );
};

const Cart = ({ items = [], checkoutUrl }) => {
  return (
    <Container>
      <a href={checkoutUrl}>
        <i className="fa fa-shopping-cart"></i> <span>Korpa</span>
        <ItemsCount>{items.length}</ItemsCount>
      </a>
      <CartWrapper>
        <CartItems>
          {items.map(({ product }, index) => {
            const {
              product_url, icon_url, name, excerpt, price, discount_price
            } = product;
            return (
              <CartItem key={index}>
                <a href={product_url}>
                  <Thumb src={icon_url} alt={name} title={excerpt} />
                  <Title>{name}</Title>
                  <Price price={price} discountPrice={discount_price} />
                </a>
              </CartItem>
            );
          })}
        </CartItems>
        <ButtonWrapper>
          <Button href={checkoutUrl}>
            <i className="fa fa-shopping-cart"></i> Moja Korpa
          </Button>
        </ButtonWrapper>
      </CartWrapper>
    </Container>
  );
};

export default Cart;
