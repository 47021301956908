import React from 'react';
import ReactDOM from 'react-dom';
import Cart from 'components/Cart';
import { ProductsList, OrderForm } from 'components/Checkout';

const renderUI = (items, onAction) => {
  const elCart = document.getElementById('shopping-cart-placeholder');
  if (elCart) {
    if (items.length > 0) {
      ReactDOM.render(
        <Cart items={items} checkoutUrl="/checkout/" />, elCart
      );
      elCart.style.display = 'inline-block';
    } else {
      elCart.style.display = 'none';
    }
  }
  const elList = document.getElementById('product-table');
  if (elList) {
    ReactDOM.render(
      <ProductsList items={items} onAction={onAction} />, elList
    );
  }
  const elOrderForm = document.getElementById('order-form');
  if (elOrderForm) {
    ReactDOM.render(<OrderForm onAction={onAction} />, elOrderForm);
  }
};

class ShopUI {
  constructor(restClient, notifyCb) {
    this.restClient = restClient;
    this.notifyCb = notifyCb || (() => {});
    this.items = [];
    this.handleOnTheFlyProducts();
    this.updateItems().then(() => {
      this.notifyCb({name: 'InitWebshop', items: this.items, fb: false});
    });
  }

  updateItems() {
    return this.restClient.getCartItems().then(items => {
      this.items = items;
      return items;
    });
  }

  addToCart(productSlug) {
    this.restClient.addToCart(productSlug).then(() => {
      this.updateItems().then((x) => {
        this.notifyCb({
          fbObject: ['AddToCart'],
          items: this.items,
          fb: true
        });
      });
    }, error => {
      console.log('Error adding item in the cart', error);
    });
  }

  removeCartItem(productSlug) {
    this.restClient.removeFromCart(productSlug).then(() => {
      this.updateItems().then(() => {
        this.notifyCb({name: 'RemoveFromCart', items: this.items, fb: false});
      });
    }, error => {
      console.log('Error removing item from cart', error);
    });
  }

  quantityCartItem(productSlug, qty) {
    this.restClient.updateCartItemQuantity(productSlug, qty).then(() => {
      this.updateItems().then(() => {
        this.notifyCb({
          name: 'QunatityChangedCart',
          items: this.items,
          fb: false
        });
      });
    }, error => {
      console.log(
        'Error setting quantity item in the cart', error, productSlug, qty
      );
    });
  }

  handleOnTheFlyProducts() {
    const products = window.$('#on-the-fly-products').children().map(
      child => window.$(child).val()
    );
    if (products.length > 0) {
      const promises = products.map(
        product => this.restClient.addToCart(product)
      );
      window.$.when(promises).done(() => {
        window.location.replace('/checkout/');
      });
    }
  }

  onSubmit({ data, onSuccess = () => {}, onError = () => {} }) {
    const { firstName, lastName, couponCode, zipCode, ...rest } = data;
    const payload = {
      first_name: firstName,
      last_name: lastName,
      coupon_code: couponCode,
      zip_code: zipCode,
      ...rest,
    };
    this.restClient.shopCheckout(payload).done(response => {
      const total = this.items.reduce((sum, item) => {
        return sum + (item.product.discount_price || item.product.price) * item.quantity;
      }, 0);
      this.notifyCb({
        fbObject: ['Purchase', {value: parseFloat(total), currency: 'RSD'}],
        items: this.items,
        fb: true
      });
      window.toastr.clear();
      window.$('#order_id').text(response.order_id);
      window.$('#order_confirm').slideDown('fast');
      window.$('#order_info').hide('slow');
      this.restClient.clearCart();
    }).fail(jqXHR => {
      let msg = 'Proveri da li su uneti podaci tačni!';
      if (jqXHR.responseJSON.code === 1) {
        msg = 'Potrebno je da izabereš barem jedan proizvod.';
      } else if (jqXHR.responseJSON.code === 2) {
        msg = {
          first_name: 'Neophondno je da uneseš tvoje ime.',
          last_name: 'Neophondno je da uneseš tvoje prezime.',
          phone: 'Neophondno je da uneseš tvoj broj telefona.',
          email: 'Neophondno je da uneseš svoju email adresu.',
          country: 'Neophondno je da uneseš zemlju za isporuku.',
          city: 'Neophondno je da uneseš grad za isporuku.',
          address: 'Neophondno je da uneseš adresu za isporuku.',
        }[jqXHR.responseJSON.field];
      }
      window.toastr.error(msg, 'Ups, greška!', {
        timeOut: 5000, extendedTimeOut: 10000
      });
    });
  }

  onAction(action, payload) {
    if (action === 'onSubmit') {
      this.onSubmit(payload);
    } else if (action === 'onInc') {
      const { product, quantity } = payload;
      this.quantityCartItem(product.slug, quantity + 1);
    } else if (action === 'onDec') {
      const { product, quantity } = payload;
      this.quantityCartItem(product.slug, quantity - 1);
    } else if (action === 'onRemove') {
      const { product } = payload;
      this.removeCartItem(product.slug)
    }
  }
}

export { renderUI };
export default ShopUI;
